.main-banner {
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: 20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            background: $primaryColor;
            border-radius: 21px;
            opacity: 1;
          }
        }

        button {
          width: 30px;
          height: 6px;
          border-radius: 100px;
          background: $alwaysWhite;
          padding: 0;
          transition: all .2s ease;
          opacity: .7;

          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  position: relative;
  @include media("<568px") {
    min-height: 75vw;
    padding-left: 2vw;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($alwaysBlack, .3);
      z-index: 3;
      opacity: 0;

      @include media("<568px") {
        opacity: 1;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    min-height: 410px;
    padding-bottom: 60px;
    position: relative;
    z-index: 5;
    padding-top: 60px;

    @include media("<568px") {
      padding-top: 20px;
      min-height: 375px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 42px;
    line-height: 1.3;
    margin-bottom: 10px;
    max-width: 650px;
    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 1.6;
    max-width: 600px;
    opacity: .75;
    @include media("<568px") {
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: 15px;
    position: absolute;
    bottom: 75px;
    left: 0;
    line-height: 33px;

    @include media("<568px") {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}